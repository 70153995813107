import {defineStore} from 'pinia'

export const useLastVisitedProducts = defineStore('lastVisitedProducts', {
    state: () => ({
        products: [],
    }),
    actions: {
        addProduct(id) {
            this.products = this.products.filter(product => product.id !== id)
            this.products.unshift({id})

            if (this.products.length > 10) {
                this.products.pop();
            }
        },
    },
    persist: {
        key: 'lastVisitedProducts',
        paths: ['products'],
    }
})
